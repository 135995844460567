import React, { useState, useEffect, useRef } from 'react';

const EverydayStrength = () => {
  // Initial angles (in degrees) for the broken branch segments:
  // We'll consider the main branch as vertical (0 degrees reference),
  // The healthy branch might be at a fixed angle.
  // For the broken branch: a is attached to main branch, b attached to a, c attached to b, d attached to c.
  // We'll store angles as relative angles from the vertical or from the previous segment.
  const [angleA, setAngleA] = useState(45);  // Angle of a from vertical
  const [angleB, setAngleB] = useState(330);  // Angle between a and b
  const [angleC, setAngleC] = useState(330);  // Angle between b and c
  const [angleD, setAngleD] = useState(330);  // Angle between c and d
  const [fullyAligned, setFullyAligned] = useState(false);
  const [streak, setStreak] = useState(0);

  // Define the healthy branch angle (a single straight line angle)
  const healthyAngle = 45; // once all segments line up, they should match this angle
  const energies = [50, 60, 70, 80, 90, 100]; 

  // Segment lengths (all equal for simplicity)
  const segmentLength = 50;
  const segmentThickness = 4;

  // The order of straightening:
  // 1) c-d angle => reduce angleD until ~0
  // 2) b-c angle => reduce angleC until ~0
  // 3) a-b angle => reduce angleB until ~0
  // Once all are 0, the branch should align straight at angleA = healthyAngle.
  // Actually we started with angleA set at healthyAngle. If you'd like a-b-c-d to form a single angle line,
  // angleA can remain constant. The "straight line" means that b,c,d align with a's direction.
  // That means angleB, angleC, angleD all become nearly 0.

  function normalizeAngle(angle) {
    let a = angle % 360;
    if (a > 180) a -= 360;
    if (a <= -180) a += 360;
    return a;
  }

  // New branch logic
  const maxBranches = 12;
  const maxBranchLength = 20; // 10% of total broken length (4 segments * 50 = 200, 10% = 20)
  const growthIncrement = 5; // grows by 5 units per click
  const [newBranches, setNewBranches] = useState([]);
//   / Track used positions to avoid closeness
  // We'll store for each segment: an array of t values of previously used positions
  const [usedPositions, setUsedPositions] = useState([[], [], [], []]); // one array per segment

  // Each new branch: { segmentIndex: 0-3, t:0-1, length:0 to maxBranchLength, angle: fixed }
  // angle chosen: -30 degrees from segment direction
  
  const handleClick = () => {
    setStreak(streak + 1); // Increment streak each click
    if (!fullyAligned) {
      // Normalize angles first
      const normD = normalizeAngle(angleD);
      const normC = normalizeAngle(angleC);
      const normB = normalizeAngle(angleB);
  
      if (Math.abs(normD) > 0.1) {
        setAngleD(normD / 2); // Now angleD moves directly toward 0
      } else if (Math.abs(normC) > 0.1) {
        setAngleC(normC / 2);
      } else if (Math.abs(normB) > 0.1) {
        setAngleB(normB / 2);
      } else {
        setFullyAligned(true);
      } 
    }else {
       // If fully aligned and streak > 33 -> grow new branches
      if (streak > 33 && newBranches.length < maxBranches) {
        setNewBranches((prev) => {
          const copy = [...prev];
          let lastBranch = copy[copy.length - 1];
          if (copy.length === 0 || (lastBranch && lastBranch.length === maxBranchLength)) {
            // Need a new branch
            const nb = createNewBranch(usedPositions);
            if (nb) {
              copy.push(nb.branch);
              setUsedPositions(nb.newUsedPositions);
            }
          } else {
            // Grow last branch
            let lb = copy[copy.length - 1];
            if (lb.length < maxBranchLength) {
              lb = {...lb, length: Math.min(lb.length + growthIncrement, maxBranchLength)};
              copy[copy.length - 1] = lb;
            }
          }
          return copy;
        });
      } else if (streak > 33 && newBranches.length > 0) {
        // If we already have some branches but still not at max and current branch not fully grown
        setNewBranches((prev) => {
          const copy = [...prev];
          let lb = copy[copy.length - 1];
          if (lb && lb.length < maxBranchLength) {
            lb = {...lb, length: Math.min(lb.length + growthIncrement, maxBranchLength)};
            copy[copy.length - 1] = lb;
          }
          return copy;
        });
      }
    }

// Start ball animation on every click
    startBallAnimation();
  };

   function createNewBranch(usedPositions) {
    // Attempt to find a segment and t that is not too close to existing
    const maxAttempts = 50;
    let attempt = 0;
    let chosenSegment = null;
    let chosenT = null;
    while (attempt < maxAttempts) {
      const segmentIndex = Math.floor(Math.random() * 4);
      const t = Math.random(); // random position along segment

      // Check closeness
      const tooClose = usedPositions[segmentIndex].some((usedT) => Math.abs(usedT - t) < 0.1);
      if (!tooClose) {
        chosenSegment = segmentIndex;
        chosenT = t;
        break;
      }
      attempt++;
    }

    if (chosenSegment === null) {
      // Couldn't find suitable spot
      return null;
    }

    // Random angle sign: top or bottom side
    const angleSign = Math.random() > 0.5 ? 1 : -1;
    const angle = angleSign * 30; // ±30 degrees

    const newBranch = { segmentIndex: chosenSegment, t: chosenT, length: 0, angle: angle };
    // Update used positions
    const newUsedPositions = [...usedPositions];
    newUsedPositions[chosenSegment] = [...newUsedPositions[chosenSegment], chosenT];

    return {branch: newBranch, newUsedPositions};
  }
  

  // Calculate the absolute angle of each segment
  // a is from vertical at angleA
  // b follows a, so absolute angle of b = angleA + angleB
  // c follows b, so absolute angle of c = angleA + angleB + angleC
  // d follows c, so absolute angle of d = angleA + angleB + angleC + angleD
  const absAngleA = angleA;
  const absAngleB = angleA + angleB;
  const absAngleC = angleA + angleB + angleC;
  const absAngleD = angleA + angleB + angleC + angleD;

  const segmentLengthAll = 50; // each segment length


  // Convert angles to radians for SVG transforms
  const toRad = (deg) => (deg * Math.PI) / 180;

  // Positions:
  // Start main branch at some point, say (100, 100)
  const mainX = 100;
  const mainY = 100;
  const mainLength = 150;

  // Healthy branch angle: a single line from main branch at top
  const healthyStartX = mainX;
  const healthyStartY = mainY + 50;
  const healthyAngleDeg = healthyAngle;
  const healthyX2 = healthyStartX + Math.cos(toRad(-healthyAngleDeg)) * 70;
  const healthyY2 = healthyStartY + Math.sin(toRad(-healthyAngleDeg)) * 70;

  // Broken branch starts lower: 
  // Let's say it starts 50px down from mainY
  const brokenStartX = mainX;
  const brokenStartY = mainY + 80;

  // Each segment is a rectangle anchored at its start point.
  // We'll represent the segment as a rect horizontally, then rotate around start.
  // The rect center at start: Actually, we can position rect so that start is at left center of rect.
  const rectWidth = segmentLength;
  const rectHeight = segmentThickness;

  // For each segment, we need to compute the end position if needed or at least transform.
  // Actually, we just need to draw them connected:
  // a starts at brokenStartX, brokenStartY
  const aEndX = brokenStartX + Math.cos(toRad(-absAngleA)) * segmentLengthAll;
  const aEndY = brokenStartY + Math.sin(toRad(-absAngleA)) * segmentLengthAll;

  // b starts at aEndX, aEndY
  const bEndX = aEndX + Math.cos(toRad(-absAngleB)) * segmentLengthAll;
  const bEndY = aEndY + Math.sin(toRad(-absAngleB)) * segmentLengthAll;

  // c starts at bEndX, bEndY
  const cEndX = bEndX + Math.cos(toRad(-absAngleC)) * segmentLengthAll;
  const cEndY = bEndY + Math.sin(toRad(-absAngleC)) * segmentLengthAll;

  // d starts at cEndX, cEndY
  const dEndX = cEndX + Math.cos(toRad(-absAngleD)) * segmentLengthAll;
  const dEndY = cEndY + Math.sin(toRad(-absAngleD)) * segmentLengthAll;

  // For drawing the rects, we place each rect so that its "start" point is at left center of rect.
  // Then rotate around that start point.
  // We'll use <g transform="translate(...) rotate(...)"> groups.
  // The rect's top-left corner (before rotation) will be at (0, -rectHeight/2), width = segmentLength.


   // Colors:
  // Main and healthy branches: green
  const mainColor = "green";
  const healthyColor = "green";

  // Broken branch segments colors (a to d) gradient from greenish to red:
  // a is mostly green, d is more red.
  // Before alignment:
  const colorA = fullyAligned ? "green" : "green";
  const colorB = fullyAligned ? "green" : "#80c040";
  const colorC = fullyAligned ? "green" : "#40ff40";
  const colorD = fullyAligned ? "green" : "#cfffcf";

  // Ball animation states
  const [progress, setProgress] = useState(0);
  const animationRef = useRef(null);

  const startBallAnimation = () => {
    // Clear any previous animation
    if (animationRef.current) clearInterval(animationRef.current);

    setProgress(0);
    animationRef.current = setInterval(() => {
      setProgress((p) => {
        const newP = p + 0.02; // increment progress
        if (newP >= 1) {
          clearInterval(animationRef.current);
          return 1;
        }
        return newP;
      });
    }, 1); // 20ms * ~50 steps = 1 second animation
  };

 // Path segments:
  // 1) From main bottom: (mainX, mainY+mainLength) to brokenStart (brokenStartX, brokenStartY)
  // 2) From brokenStart to aEnd
  // 3) From aEnd to bEnd
  // 4) From bEnd to cEnd
  // 5) From cEnd to dEnd

  function dist(x1,y1,x2,y2) {
    return Math.sqrt((x2 - x1)**2 + (y2 - y1)**2);
  }

  const mainBottomX = mainX;
  const mainBottomY = mainY + mainLength;

  const seg1Len = dist(mainBottomX, mainBottomY, brokenStartX, brokenStartY);
  const seg2Len = dist(brokenStartX, brokenStartY, aEndX, aEndY);
  const seg3Len = dist(aEndX, aEndY, bEndX, bEndY);
  const seg4Len = dist(bEndX, bEndY, cEndX, cEndY);
  const seg5Len = dist(cEndX, cEndY, dEndX, dEndY);

  const totalLength = seg1Len + seg2Len + seg3Len + seg4Len + seg5Len;


  // Compute ball position based on progress along path
  const currentDist = totalLength * progress;

  function interpolateSegment(x1, y1, x2, y2, distSoFar, targetDist) {
    const segLen = dist(x1,y1,x2,y2);
    const remain = targetDist - distSoFar;
    const t = remain / segLen;
    const X = x1 + t*(x2 - x1);
    const Y = y1 + t*(y2 - y1);
    return {X,Y};
  }

  let ballX, ballY;
  let distSoFar = 0;

  if (currentDist <= seg1Len) {
    // On segment 1
    const {X,Y} = interpolateSegment(mainBottomX, mainBottomY, brokenStartX, brokenStartY, distSoFar, currentDist);
    ballX = X; ballY = Y;
  } else if (currentDist <= seg1Len + seg2Len) {
    distSoFar = seg1Len;
    const {X,Y} = interpolateSegment(brokenStartX, brokenStartY, aEndX, aEndY, distSoFar, currentDist);
    ballX = X; ballY = Y;
  } else if (currentDist <= seg1Len + seg2Len + seg3Len) {
    distSoFar = seg1Len + seg2Len;
    const {X,Y} = interpolateSegment(aEndX, aEndY, bEndX, bEndY, distSoFar, currentDist);
    ballX = X; ballY = Y;
  } else if (currentDist <= seg1Len + seg2Len + seg3Len + seg4Len) {
    distSoFar = seg1Len + seg2Len + seg3Len;
    const {X,Y} = interpolateSegment(bEndX, bEndY, cEndX, cEndY, distSoFar, currentDist);
    ballX = X; ballY = Y;
  } else {
    distSoFar = seg1Len + seg2Len + seg3Len + seg4Len;
    const {X,Y} = interpolateSegment(cEndX, cEndY, dEndX, dEndY, distSoFar, currentDist);
    ballX = X; ballY = Y;
  }

   // Define an array for the broken branch segments for new branch attachment calculations
   const segments = [
    {startX: brokenStartX, startY: brokenStartY, endX: aEndX, endY: aEndY, angle: absAngleA},
    {startX: aEndX, startY: aEndY, endX: bEndX, endY: bEndY, angle: absAngleB},
    {startX: bEndX, startY: bEndY, endX: cEndX, endY: cEndY, angle: absAngleC},
    {startX: cEndX, startY: cEndY, endX: dEndX, endY: dEndY, angle: absAngleD}
  ];

  // Draw new branches if any
  // For each new branch: find point on chosen segment at fraction t, draw line at angle from that direction
  // Segment direction angle is known from the segment angles used above (-absAngleX)
  // Actually segment angle = that segment's absolute angle (like absAngleA for first segment?), we can deduce from segments array's angle property.

  const newBranchesLines = newBranches.map((nb, i) => {
    const sg = segments[nb.segmentIndex];
    // Interpolate point at t along segment
    const segLen = dist(sg.startX, sg.startY, sg.endX, sg.endY);
    const offsetDist = segLen * nb.t;
    // const dx = sg.endX - sg.startX;
    // const dy = sg.endY - sg.startY;
    const px = sg.startX + ( (sg.endX - sg.startX) * nb.t );
    const py = sg.startY + ( (sg.endY - sg.startY) * nb.t );

    // Segment angle is sg.angle. 
    // The new branch angle from segment direction: segment direction is -sg.angle?
    // Actually, we used absAngle for segment direction. It's the absolute angle. The actual rotation needed is -the absolute angle.
    const segmentAngle = sg.angle; 
    const branchAngle = segmentAngle + nb.angle; 
    // nb.angle = -30 degrees from segment direction

    const branchX2 = px + Math.cos(toRad(-branchAngle)) * nb.length;
    const branchY2 = py + Math.sin(toRad(-branchAngle)) * nb.length;

    return (
      <line
        key={i}
        x1={px}
        y1={py}
        x2={branchX2}
        y2={branchY2}
        stroke="green"
        strokeWidth={4}
      />
    );
  });



  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      backgroundColor: '#f5f5f5',
      fontFamily: 'Roboto, sans-serif',
      padding: '20px'
    }}>
      <h5 style={{ marginBottom: '10px', color: '#cfd1cf', fontWeight: 500 }}>
        Consistency
      </h5>
      <div style={{
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        padding: '10px',
        maxWidth: '320px',
        width: '100%',
        textAlign: 'center'
      }}>
        <svg width={300} height={300} style={{ marginTop: 10, border: '1px solid #ccc', borderRadius: '8px' }}>
          {/* Main branch - vertical line */}
          <line
            x1={mainX}
            y1={mainY}
            x2={mainX}
            y2={mainY + mainLength}
            stroke={mainColor}
            strokeWidth={4}
          />
  
          {/* Healthy branch */}
          {/* <line
            x1={healthyStartX}
            y1={healthyStartY}
            x2={healthyX2}
            y2={healthyY2}
            stroke={healthyColor}
            strokeWidth={4}
          />
   */}
          {/* Broken branch segments (a,b,c,d) as rectangles */}
          {/* a segment */}
          <g transform={`translate(${brokenStartX}, ${brokenStartY}) rotate(${-absAngleA}, 0,0)`}>
            <rect x={0} y={-rectHeight/2} width={rectWidth} height={rectHeight} fill={colorA} />
          </g>
  
          {/* b segment */}
          <g transform={`translate(${aEndX}, ${aEndY}) rotate(${-absAngleB},0,0)`}>
            <rect x={0} y={-rectHeight/2} width={rectWidth} height={rectHeight} fill={colorB} />
          </g>
  
          {/* c segment */}
          <g transform={`translate(${bEndX}, ${bEndY}) rotate(${-absAngleC},0,0)`}>
            <rect x={0} y={-rectHeight/2} width={rectWidth} height={rectHeight} fill={colorC} />
          </g>
  
          {/* d segment */}
          <g transform={`translate(${cEndX}, ${cEndY}) rotate(${-absAngleD},0,0)`}>
            <rect x={0} y={-rectHeight/2} width={rectWidth} height={rectHeight} fill={colorD} />
          </g>
  
          {/* White ball */}
          <circle cx={ballX} cy={ballY} r={5} fill="white" />

         {/* New grown branches */}
         {/* {newBranchesLines} */}
        </svg>
        <p style={{ marginTop: 10, color: '#555', fontSize: '1rem' }}>
          {/* {streak} times streak */}
        </p>
        <div style={{ marginTop: '20px' }}>
          <button
            onClick={handleClick}
            style={{
              background: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
            }}
          >
            Try again.
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default EverydayStrength;
