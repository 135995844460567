import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { ThemeProvider, Container  } from 'react-bootstrap';  // Correct import for ThemeProvider
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
// import { firestore, storage } from './firebase';
import { Form, Button, Modal } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

import HomePage from './HomePage';  // Assuming you already created it
import TimeHeals from './apps/TimeHeals';  // Import your individual app components
import EnergyPools from './apps/EnergyPools';  // Import your individual app components
import EnergyofEverything from './apps/EnergyofEverything';  // Import your individual app components
import EverythingisPerceived from './apps/EverythingisPerceived';  // Import your individual app components
import ExperiencesDie from './apps/ExperiencesDie';  // Import your individual app components
import ForceofEverything from './apps/ForceofEverything';  // Import your individual app components
import IncrementalMagic from './apps/IncrementalMagic';  // Import your individual app components
import PastChanges from './apps/PastChanges';  // Import your individual app components
import WeCantGoThere from './apps/WeCantGoThere';  // Import your individual app components
import DayNight from './apps/DayNight';
import FutureTree from './apps/FutureTree';
import Gravitude from './apps/Gravitude';
import ChooseFuture from './apps/ChooseFuture'
import EverydayStrength from './apps/EverydayStrength'
import AntiProcastinator from './apps/AntiProcastinator'
import 'bootstrap/dist/css/bootstrap.min.css';

const auth = getAuth();
const firestore = getFirestore();

function App() {

  // console.log('TimeHeals: ', TimeHeals);  
  // console.log('EnergyPools:', EnergyPools); 
  const [conversation, setConversation] = useState(null);
  const [show, setShow] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [showMysub, setShowMySub] = useState(false);
  // Add a state variable for the current audio URL
  const [currentAudioURL, setCurrentAudioURL] = useState('https://www.example.com/path-to-your-audio.mp3'); 

  const handleCloseMySub = () => setShowMySub(false);
  const handleShowMySub = () => {
    // mixpanel.track('mysubsfromup', {
    //   // message: "card clicked while signed in"
    // })
    setShowMySub(true);
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log('app conversation1: ', conversation)

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsSignedIn(true);
        setUserName(user.displayName);
        setUserPhoto(user.photoURL);
        setShowMySub(true)
      } else {
        setIsSignedIn(false);
        setUserPhoto('');
        setUserName('')
      }
    });
  }, []);

  const signInWIthGoogle = async () => {

    // mixpanel.track('signIn', {
    //   message: "signin button clicked"
    // })

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      const guser = result.user.displayName;
      const userEmail = result.user.email;
      const userPhoto = result.user.photoURL;
      const userID = result.user.uid; // Get the user ID
      console.log(guser);
  
      setUserName(guser);
      setUserPhoto(result.user.photoURL);
      setIsSignedIn(true);
  
      // Check if user document exists in 'users' collection
      const userDocRef = doc(firestore, 'users', userID);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        // Create the user document if it doesn't exist
        await setDoc(userDocRef, {
          displayName: guser,
          photoURL: userPhoto,
          userId: userID,
          email: userEmail
          // Add other user details here if needed
        });
        console.log("User document created");
      } else {
        console.log("User document already exists");
      }
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Error during sign-in:", error);
      // ...
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setIsSignedIn(false);
        setUserName('');
        setUserPhoto('');
      })
      .catch((error) => {
        console.error("Error during sign-out:", error);
      });
  };
  

  const handleConversationTransfer = (conversationText) => {
    console.log('app conversation2: ', conversationText)
    setConversation(conversationText)
  };

  const mysubs = () => {
    handleShowMySub();
  };


  return (
    <div className="App">
    {/* <div style={{ flex: 1 }} className="container" > */}
    <div className="container-fluid p-0" >
      <nav bg="dark" className="navbar navbar-expand navbar-dark" style={{"padding":"0px", margin:0}} >
          <a href="https://www.symbols.energy" className="navbar-brand" style={{"color": '#6c757d', marginLeft:15}} > 
          {/* <img src="./logo.png" width="25" alt="logo" />   */}
          symbols.energy
          </a>
          
          <div class="collapse navbar-collapse" id="toggleMobileMenu">
            <ul class="navbar-nav">
            </ul>
          </div>
        
      
          <span class="navbar-text">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              {/* display profile photo here */}
              <li className="nav-item">
              <a onClick={handleShowMySub}>
              {/* <a onClick={show ? handleClose : handleShow}> */}
                <img
                  src={isSignedIn && userPhoto ? userPhoto : "./account.png"}
                  alt={userName || "placeholder"}
                  style={{ width: 35, height: 35, borderRadius: '50%' }}
                />
              </a>
              </li>
            </ul>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a onClick={show ? handleClose : handleShow} className="nav-link" style={{ color: '#CCCCCC', margin: 5 }}>
                    <img src={show ? "./close.png" : "./menu.png"} width="35" alt="menu" />
                  </a>
                </li>
              </ul>
          </div>
          
          </span> 
          
          
      </nav>
  </div>
    <ThemeProvider>
    {/* <BrowserRouter> */}
    <Router>
    <Container>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/time-heals" element={<TimeHeals/>} />
        <Route path="/energy-pools" element={<EnergyPools/>} />
        <Route path="/energy-of-everything" element={<EnergyofEverything/>} />
        <Route path="/everything-is-perceived" element={<EverythingisPerceived/>} />
        <Route path="/experiences-die" element={<ExperiencesDie/>} />
        <Route path="/force-of-everything" element={<ForceofEverything/>} />
        <Route path="/incremental-magic" element={<IncrementalMagic/>} />
        <Route path="/past-changes" element={<PastChanges/>} />
        <Route path="/we-cant-go-there" element={<WeCantGoThere/>} />
        <Route path="/day-night" element={<DayNight/>} />
        <Route path="/future-tree" element={<FutureTree/>} />
        <Route path="/gravitude" element={<Gravitude/>} />
        <Route path="/choose-future" element={<ChooseFuture/>} />
        <Route path="/everyday-strength" element={<EverydayStrength/>} />
        <Route path="/anti-procastinator" element={<AntiProcastinator/>} />
         
        {/* Add routes for other apps here */}
      </Routes>
      </Container>
    </Router>
    {/* </BrowserRouter> */}
    </ThemeProvider>

    <Offcanvas style={{height: 170}} scroll={true} placement={'top'} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <a href="https://symbols.energy" class="navbar-brand" style={{"color": '#6c757d', margin:5}} > symbols.energy  </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Button variant={isSignedIn ? 'light':'danger'} 
              style={{ borderColor: '#C7BEBE', margin: 4, marginTop: 8, height: 50, borderRadius: 100, fontSize: 20 }} 
              className="w-100" 
              onClick={isSignedIn ? handleSignOut : signInWIthGoogle}>
                {isSignedIn ? `Sign Out (${userName})` : 'Sign In with Google'}
            </Button>
        </Offcanvas.Body>
    </Offcanvas>

    <footer 
  className="footer-audio-player" 
  style={{ 
    position: 'fixed', 
    bottom: 0, 
    marginTop: '50px', // Space between the footer and the content above
    width: '100%', 
    background: '#f8f9fa', 
    borderTop: '1px solid #ddd', 
    padding: '10px',
    zIndex: 1000, // Ensure it stays above other elements
    left: '50%', // Move it to the center horizontally
    transform: 'translateX(-50%)', // Center align horizontally
    textAlign: 'center',

  }}
>
  {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
    <audio 
      controls 
      style={{ 
        width: '100%', 
        maxWidth: '400px' 
      }} 
      src={currentAudioURL}
    >
      Your browser does not support the audio element.
    </audio>
  </div> */}
</footer>



    </div>
  );
}

export default App;
