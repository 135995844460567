import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  getDoc, 
  setDoc,
  increment,
  orderBy
} from "firebase/firestore";
import { Card, Button, Modal, Table, Form, Container, Row, Col } from "react-bootstrap";
import { getAuth, useAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
// import { useAuth } from "./apps/context/AuthContext"; // Adjust the path to your AuthContext file
// import { useAuth } from "./context/AuthContext"; // Adjust the path to your AuthContext file
// import { useAuth } from "./context/AuthContext"; // Adjust path to your AuthContext file



const Gravitude = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showAmplifyModal, setShowAmplifyModal] = useState(false);
  const [showBlessings, setShowBlessings] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedAmplifiers, setSelectedAmplifiers] = useState([]);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  // const userLocation = "USA"; // Replace with user's location (e.g., from auth/profile)
  const [userIddd, setUserIdd] = useState("");
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [showSignInButton, setShowSignInButton] = useState(false); // Add state for sign-in modal
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { userIdd } = getAuth();

  const handleSignInModalClose = () => setShowSignInModal(false);
  const handleSignInModalShow = () => setShowSignInModal(true);

  const firestore = getFirestore();


  // const userID = "CURRENT_USER_ID3"; // Replace this with the current user's ID from authentication

  const db = getFirestore(); // Firestore instance
  const auth = getAuth();

  const fullMessage = (
    <>
      <p>
        The Energy of a Mother is any constant, unwavering force of love and support.
      </p>
      <p>
        Channelling a message helps send gratitude and intention to Mother Earth ♁. Earth's gravity is more than a force—<i>it’s a constant, grounding embrace from the massive entity</i> 🫂 <i>wherever you are and whichever posture you choose to take.</i>
      </p>
      <p>
        You can connect with her by feeling this force in your body, wherever you are. <b>To help others send gratitude, 💌 focus on where gravity is felt within your body as you read their message. It helps to say their name too.</b>
      </p>
      <p>
        By doing so, we strengthen our bond with Earth ❤️🌍 and help others do the same.
      </p>
    </>
  );

  const truncatedMessage = (
    <>
    
      <p>
        <a>To help others send gratitude, 💌 focus on where gravity is felt within your body as you read their message. It helps to say their name too.</a>
      </p>
    </>
  );

  useEffect(() => {
    if (userIdd) {
      console.log("User ID is:", userIdd);
    }
  }, [userIdd]);
  

      useEffect(() => {
        
        const q = query(
          collection(db, "messages"),
          orderBy("timestamp", "desc") // Sort by timestamp in descending order
        );
      
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const fetchedMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(fetchedMessages);
        });
      
        return () => unsubscribe();
      }, [db]);

      // console.log('user: ', userIdd)

    const postMessage = async () => {
      const user = auth.currentUser;
    
      if (user) {
        const userid = user.uid;
        // setUserIdd(userid);
    
        if (message.trim().length > 0) {
          try {
            // Reference to the user's document in the "earthusers" collection
            const userDocRef = doc(db, "earthusers", userid);
            const userDoc = await getDoc(userDocRef);
    
            if (userDoc.exists()) {
              const { displayName, location } = userDoc.data();
    
              // Add the message to the "messages" collection
              await addDoc(collection(db, "messages"), {
                content: message,
                authorId: userid, // Replace with current user ID
                amplifiers: [],
                timestamp: new Date(),
                photo: user.photoURL, // Still using auth for photo
                username: displayName || "Anonymous", // Default to "Anonymous" if no displayName
                location: location || "Earth", // Default to "Earth" if no location
              });
    
              // Increment the messagesSent field in the user's document
              await updateDoc(userDocRef, {
                messageSent: increment(1),
              });
    
              console.log("Message posted successfully, and messagesSent incremented.");
              setMessage(""); // Clear the input field
            } else {
              console.error("User document does not exist in 'earthusers' collection.");
            }
          } catch (error) {
            console.error("Error posting message or updating messagesSent:", error);
          }
        } else {
          console.log("Empty text box");
          return;
        }
      } else {
        console.log("No user is signed in.");
        setShowSignInModal(true);
      }
    };
  
  

  // Handle amplify action (triggered after user swears and confirms)
    const amplifyMessage = async () => {
      const user = auth.currentUser;

      if (user) {
        const userid = user.uid;
        // setUserIdd(userid);

        const messageRef = doc(db, "messages", currentMessageId);
        const userDocRef = doc(db, "earthusers", userid); // Reference to the user's document in earthusers collection

        try {
          // Fetch user location from Firestore
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userLocation = userDoc.data().location || "Earth"; // Default to "Earth" if location is missing

            const amplifierData = `${userid}&${userLocation}`;

            // Update the amplifiers array in the message document
            await updateDoc(messageRef, {
              amplifiers: arrayUnion(amplifierData), // Add userID to the amplifiers array
            });

            // Increment the messageAmplified field in the user's document
            await updateDoc(userDocRef, {
              messageAmplified: increment(1), // Atomically increment the field
            });

            setShowAmplifyModal(false); // Close the modal after amplifying
            setShowBlessings(true);

            console.log("Message amplified and messagesAmplified incremented successfully.");
          } else {
            console.error("User document does not exist.");
          }
        } catch (error) {
          console.error("Error amplifying message or updating messagesAmplified:", error);
        }
      } else {
        console.log("No user is signed in.");
        setShowSignInButton(true);
      }
    };


  // Open instructional modal before amplifying
  const handleAmplifyClick = (messageId) => {
    setCurrentMessageId(messageId);
    setShowAmplifyModal(true);
  };

  // Open modal to view amplifiers information
  const handleInfoClick = (amplifiers) => {
    setSelectedAmplifiers(amplifiers);
    setShowInfoModal(true);
  };

  // Parse amplifiers to get location counts
  const parseAmplifiers = (amplifiers) => {
    const locationCounts = {};
    amplifiers.forEach((entry) => {
      const [, location] = entry.split("&");
      if (locationCounts[location]) {
        locationCounts[location]++;
      } else {
        locationCounts[location] = 1;
      }
    });
    return Object.entries(locationCounts).map(([location, count]) => ({ location, count }));
  };

  const shareToFriends = () => {
    const text = "Come see this amazing app that lets you send gratitude to Mother Earth! 🌍✨ Check it out at: https://www.ToMotherEarth.com";
  
    if (navigator.share) {
      // Use Web Share API if available
      navigator.share({
        title: "To Mother Earth",
        text: text,
        url: "https://www.ToMotherEarth.com", // Include the app URL
      })
        .then(() => console.log("Content shared successfully!"))
        .catch((error) => console.error("Error sharing content:", error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      alert("Sharing is not supported on this browser. Please copy the link and share it manually.");
    }
  };

  const signInWithGoogle = async () => {

    // mixpanel.track('signIn', {
    //   message: "signin button clicked"
    // })

    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      const guser = result.user.displayName;
      const userEmail = result.user.email;
      const userPhoto = result.user.photoURL;
      const userID = result.user.uid; // Get the user ID
      console.log(guser);
  
      setUserName(guser);
      setUserPhoto(result.user.photoURL);
      setIsSignedIn(true);
  
      // Check if user document exists in 'users' collection
      const userDocRef = doc(firestore, 'earthusers', userID);
      const userDocSnapshot = await getDoc(userDocRef);
  
      if (!userDocSnapshot.exists()) {
        // Create the user document if it doesn't exist
        await setDoc(userDocRef, {
          displayName: guser,
          photoURL: userPhoto,
          userId: userID,
          email: userEmail,
          location: 'Earth'
          // Add other user details here if needed
        });
        console.log("User document created");
      } else {
        console.log("User document already exists");
      }
      setShowSignInButton(false)
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Error during sign-in:", error);
      // ...
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setIsSignedIn(false);
        setUserName('');
        setUserPhoto('');
      })
      .catch((error) => {
        console.error("Error during sign-out:", error);
      });
  };

  return (
    <Container className="mt-5">
      {/* Header Section */}
      <Row>
        <Col>
          <h3 className="text-center text-muted">
            <b style={{color:'#949494'}}>Let's Channel Gratitude to Mother Earth.</b>
          </h3>
        </Col>
      </Row>

      {/* Post Message Section */}
      <Row 
        className="mt-4">
        <Col md={{ span: 8, offset: 2 }}>
          <Form>
            <Form.Group controlId="messageBox">
              <Form.Control
                as="textarea" // This makes the input a multi-line text area
                placeholder="Post a message to Mother Earth. "
                maxLength="111"
                value={message}
                rows={2} // Sets the number of visible rows
                onChange={(e) => setMessage(e.target.value)}
                className="p-3 shadow-sm"
              />
            </Form.Group>
            <Button className="mt-4 w-100 btn-primary btn-lg shadow" style={{ borderRadius:25}} onClick={postMessage}>
              <img src="./mother.png" width="25" alt="mother" /> Send Gratitude
            </Button>
          </Form>
        </Col>
      </Row>

     {/* Display Messages */}
      <Row
        style={{
          margin: 5,
          // maxHeight: "550px",
          overflowY: "auto",
          paddingRight: "0px",
        }}
        className="mt-5"
      >
        {messages.map((msg) => {
          const hasChanneled =
              userIdd !== "" &&
              (msg.amplifiers || []).some((amplifier) => amplifier.startsWith(`${userIdd}&`));

          return (
            <Col xs={12} sm={12} md={12} lg={12} key={msg.id} className="mb-4">
              <Card style={{ marginBottom: 10 }} className="shadow-sm h-100">
                <Card.Body>
                  {/* Display poster's profile photo, username, and location */}
                  <div className="d-flex align-items-center mb-3">
                    <img
                      src={msg.photo || "./default-profile.png"} // Fallback to default image if authorPhoto is missing
                      alt={msg.username || "Anonymous"}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginRight: 10,
                      }}
                    />
                    <div>
                      {/* <p className="mb-0 fw-bold">@{(msg.username || "Anonymous").replace(/\s+/g, "")}</p> */}
                      <p className="mb-0 fw-bold">{msg.username || "Anonymous"}</p>
                      <p
                        className="mb-0 text-muted"
                        style={{ fontSize: "0.9rem" }}
                      >
                        {"from " + (msg.location || "Earth")} <i> is saying...</i>
                      </p>
                    </div>
                  </div>

                  {/* Message content */}
                  <Card.Text
                    style={{ marginBottom: 25 }}
                    className="text-muted"
                  >
                    {msg.content}
                  </Card.Text>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      variant="light"
                      className="btn-sm flex-grow-1 me-2"
                      style={{
                        minWidth: "100px",
                        maxWidth: "400px",
                        height: "40px",
                        backgroundColor: hasChanneled ? "#4CAF50" : "white", // Change color if already channeled
                        color: hasChanneled ? "white" : "black", // Adjust text color for contrast
                        borderColor: hasChanneled ? "#4CAF50" : "#ced4da", // Adjust border color
                      }}
                      onClick={() => handleAmplifyClick(msg.id)}
                      // disabled={hasChanneled} // Optional: disable button if already channeled
                    >
                     <img
                        src={hasChanneled ? "./podcasts.png" : "./podcasts2.png"} // Dynamically change the source based on hasChanneled
                        width="22"
                        alt="amplify"
                      />{" "}
                      channel ({(msg.amplifiers || []).length})
                    </Button>
                    <Button
                      variant="light"
                      className="btn-sm flex-grow-1"
                      style={{
                        minWidth: "100px",
                        maxWidth: "400px",
                        height: "40px",
                      }}
                      onClick={() => handleInfoClick(msg.amplifiers || [])}
                    >
                      <img
                        src="./person2.png"
                        width="22"
                        alt="amplifiers"
                      />{" "}
                      channels
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* Amplify Instructional Modal */}
      <Modal show={showAmplifyModal} onHide={() => setShowAmplifyModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Channel a Message</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <div>
            {showFullMessage ? fullMessage : truncatedMessage}
            <button
              onClick={() => setShowFullMessage(!showFullMessage)}
              style={{
                marginTop: "10px",
                backgroundColor: "transparent",
                border: "none",
                color: "#007BFF",
                cursor: "pointer",
                fontSize: "1rem",
              }}
            >
              {showFullMessage ? "Read Less" : "Read More"}
            </button>
          </div>

          {/* <p>
            Do you solemnly swear to use this knowledge and power for good only?
          </p>
          <Form.Check
            type="checkbox"
            label="Do not show this message again."
            id="doNotShowAgain"
            onClick={(e) => localStorage.setItem("hideInstructionModal", e.target.checked)}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowAmplifyModal(false)}>
            tell a Friend
          </Button> */}
          <Button variant="light" onClick={amplifyMessage}>
          <img src="./podcasts2.png" width="22" alt="amplify" /> channel
          </Button>
        </Modal.Footer>
        {showSignInButton && (
                      <Button style={{width:'100%', marginTop:10}} variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )}
      </Modal>

      {/* Amplifiers Info Modal */}
      <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Earth Channels Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAmplifiers.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {parseAmplifiers(selectedAmplifiers).map(({ location, count }) => (
                  <tr key={location}>
                    <td>{location}</td>
                    <td>{count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center text-muted">No Channels yet.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInfoModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Thank you Modal */}
      <Modal show={showBlessings} onHide={() => setShowBlessings(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thank you! </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <div
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              maxWidth: "600px",
              margin: "20px auto",
            }}
          >
            <p style={{ fontSize: "1.2rem", color: "#555" }}>
              Thank you for helping others !
            </p>
            <p style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#2c3e50", marginTop: "15px" }}>
              May your mind be supported by the strength and nourished by the love of Mother Earth.
            </p>
          </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => shareToFriends()}>
          <img src="./share.png" width="22" alt="share" /> Tell your Friends
          </Button>
          <Button variant="light" onClick={() => setShowBlessings(false)}>
            {/* <img src="./close.png" width="22" alt="close" />  */}
            Finish
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={showSignInModal}
        onHide={handleSignInModalClose}
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign In</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* I will not close if you click outside me. Do not even try to press
          escape key. */}
          
            {/* <Button style={{width:'100%', marginTop:10, borderRadius:25}} variant="danger" onClick={signInWithGoogle}>
              Sign in with Google
            </Button> */}

            <Button variant={isSignedIn ? 'light':'danger'} 
              style={{ borderColor: '#C7BEBE', margin: 4, marginTop: 8, height: 50, borderRadius: 100, fontSize: 20 }} 
              className="w-100" 
              onClick={isSignedIn ? handleSignOut : signInWithGoogle}>
                {isSignedIn ? `Sign Out (${userName})` : 'Sign In with Google'}
            </Button>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleSignInModalClose}>
            Close
          </Button> */}
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
        <>

        </>
      </Modal>
    </Container>
  );
};

export default Gravitude;
