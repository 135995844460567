import React, { useEffect, useRef, useState } from "react";

const UniformTravelWithSelection = () => {
  const canvasRef = useRef(null);
  const [viewMode, setViewMode] = useState("circlePolygonInside"); // Default view mode

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const circleRadius = 100; // Radius of the circle
    const sides = 6; // Number of sides for the polygon
    const circlePerimeter = 2 * Math.PI * circleRadius; // Perimeter of the circle
    const polygonSideLength = circlePerimeter / sides; // Side length of the polygon

    // Generate polygon vertices
    const polygonVertices = [];
    for (let i = 0; i < sides; i++) {
      const angle = (2 * Math.PI * i) / sides;
      const x = centerX + circleRadius * Math.cos(angle);
      const y = centerY + circleRadius * Math.sin(angle);
      polygonVertices.push({ x, y });
    }

    // Initial variables
    let circleAngle = 0; // Angle for the ball on the circle
    let polygonProgress = 0; // Progress along the polygon's perimeter
    const cornerDotSizes = Array(sides).fill(2); // Initial size of black dots at polygon corners
    const redDotSize = 5; // Size of the red moving dot
    let lastSegmentIndex = -1; // Track which corner was last passed

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

      const polygonCenterX =
        viewMode === "circlePolygonSideBySide" ? centerX + 250 : centerX;

      if (viewMode === "circle" || viewMode === "circlePolygonSideBySide" || viewMode === "circlePolygonInside") {
        // Draw the circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, circleRadius, 0, Math.PI * 2);
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        ctx.stroke();
      }

      if (viewMode === "polygon" || viewMode === "circlePolygonSideBySide" || viewMode === "circlePolygonInside") {
        // Generate polygon vertices for the shifted polygon
        const shiftedPolygonVertices = polygonVertices.map((vertex) => ({
          x: vertex.x + (polygonCenterX - centerX),
          y: vertex.y,
        }));

        // Draw the polygon
        ctx.beginPath();
        ctx.moveTo(shiftedPolygonVertices[0].x, shiftedPolygonVertices[0].y);
        shiftedPolygonVertices.forEach((vertex, index) => {
          const nextVertex =
            shiftedPolygonVertices[(index + 1) % shiftedPolygonVertices.length];
          ctx.lineTo(nextVertex.x, nextVertex.y);
        });
        ctx.closePath();
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;
        ctx.stroke();

        // Draw increasing dots at polygon corners, clipped within the polygon
        shiftedPolygonVertices.forEach((vertex, index) => {
          ctx.save(); // Save the current canvas state

          // Clip to the polygon region
          ctx.beginPath();
          ctx.moveTo(shiftedPolygonVertices[0].x, shiftedPolygonVertices[0].y);
          shiftedPolygonVertices.forEach((v, i) => {
            const nextV =
              shiftedPolygonVertices[(i + 1) % shiftedPolygonVertices.length];
            ctx.lineTo(nextV.x, nextV.y);
          });
          ctx.closePath();
          ctx.clip();

          // Draw the black dot at the corner
          ctx.beginPath();
          ctx.arc(vertex.x, vertex.y, cornerDotSizes[index], 0, Math.PI * 2);
          ctx.fillStyle = "black";
          ctx.fill();

          ctx.restore(); // Restore the canvas state
        });

        // Calculate the position of the ball on the polygon
        const totalPolygonPerimeter = polygonSideLength * sides;
        const currentSegmentIndex = Math.floor(
          (polygonProgress / totalPolygonPerimeter) * sides
        );
        const segmentStart = shiftedPolygonVertices[currentSegmentIndex];
        const segmentEnd =
          shiftedPolygonVertices[(currentSegmentIndex + 1) % shiftedPolygonVertices.length];
        const segmentFraction =
          (polygonProgress % polygonSideLength) / polygonSideLength;

        const polygonX =
          segmentStart.x + (segmentEnd.x - segmentStart.x) * segmentFraction;
        const polygonY =
          segmentStart.y + (segmentEnd.y - segmentStart.y) * segmentFraction;

        // Draw the moving dot on the polygon
        ctx.beginPath();
        ctx.arc(polygonX, polygonY, redDotSize, 0, Math.PI * 2);
        ctx.fillStyle = "red";
        ctx.fill();

        // Check if the polygon ball has passed a corner
        if (currentSegmentIndex !== lastSegmentIndex) {
          cornerDotSizes[currentSegmentIndex] += redDotSize * 0.5; // Increase the size of the black dot
          lastSegmentIndex = currentSegmentIndex; // Update the last segment index
        }

        polygonProgress = (polygonProgress + 1) % totalPolygonPerimeter; // Increment progress along the polygon
      }

      if (viewMode === "circle" || viewMode === "circlePolygonSideBySide" || viewMode === "circlePolygonInside") {
        // Calculate the position of the ball on the circle
        const circleX = centerX + circleRadius * Math.cos(circleAngle);
        const circleY = centerY + circleRadius * Math.sin(circleAngle);

        // Draw the moving dot on the circle
        ctx.beginPath();
        ctx.arc(circleX, circleY, redDotSize, 0, Math.PI * 2);
        ctx.fillStyle = "red";
        ctx.fill();
      }

      // Update angles
      circleAngle = (circleAngle + 0.01) % (2 * Math.PI); // Increment angle for the circle
      requestAnimationFrame(draw); // Call draw on the next animation frame
    };

    draw(); // Start the animation
  }, [viewMode]); // Redraw whenever the view mode changes

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflowX: viewMode === "circlePolygonSideBySide" ? "scroll" : "hidden",
        backgroundColor: "#f0f8ff",
      }}
    >
      <select
        value={viewMode}
        onChange={(e) => setViewMode(e.target.value)}
        style={{
          marginBottom: "20px",
          padding: "10px",
          fontSize: "16px",
        }}
      >
        <option value="circle">You as You</option>
        {/* <option value="polygon">You as Memory</option> */}
        {/* <option value="circlePolygonSideBySide">Circle and Polygon Side by Side</option> */}
        <option value="circlePolygonInside">You as You married to Memory</option>
      </select>
      <canvas
        ref={canvasRef}
        width={viewMode === "circlePolygonSideBySide" ? 800 : 400} // Increased width for side-by-side
        height={400}
        style={{
          border: "1px solid black",
        }}
      />
    </div>
  );
};

export default UniformTravelWithSelection;
