import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userIdd, setUserIdd] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();

    // Monitor the user's authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserIdd(user.uid); // Set userIdd from the authenticated user
      } else {
        setUserIdd(null); // Clear userIdd if user is signed out
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ userIdd, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
