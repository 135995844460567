import React, { useRef, useEffect, useState } from "react";
import axios from 'axios';
// import fs from "fs";
// import path from "path";
import OpenAI from "openai";

const openai = new OpenAI({apiKey:'', dangerouslyAllowBrowser: true});

const DayNight = () => {
  const canvasRef = useRef(null);
  const [anxietyLevel, setAnxietyLevel] = useState(0); // Anxiety level (0-5)
  const [nLevel, setNLevel] = useState(3); // N Level (3-9)
  const [audio, setAudio] = useState(null); // Audio object
  const [isPlaying, setIsPlaying] = useState(false); // Play/Pause state
  const [currentN, setCurrentN] = useState(3);
  const [isPaused, setIsPaused] = useState(false);


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const globeRadius = 100; // Radius of the globe
    const eyeX = 100; // Static x position of the eye
    const eyeY = 100; // Static y position of the eye
    const eyeRadius = 30; // Radius of the eye (light source)
    const questionMarks = []; // Array to store question mark positions

    let rotationAngle = 0; // Current rotation angle of the globe

    // Generate question marks with varying sizes from center to edge
    for (let r = 0; r <= globeRadius; r += 15) {
      for (let angle = 0; angle < 2 * Math.PI; angle += Math.PI / 10) {
        const x = centerX + r * Math.cos(angle);
        const y = centerY + r * Math.sin(angle);
        const size = (r / globeRadius) * 10 + 5; // Size increases from 5 at the center to 15 at the edge
        questionMarks.push({ x, y, angle, size }); // Store angle and size for rotation
      }
    }

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas

      // Draw the static eye (light source)
      ctx.beginPath();
      ctx.arc(eyeX, eyeY, eyeRadius, 0, Math.PI * 2);
       // // Adjust color based on anxiety level (yellow to red gradient)
      const anxietyColor2 = `rgb(255, ${255 - anxietyLevel * 51}, 0)`; // Gradual shift to red
      ctx.fillStyle = anxietyColor2;
      ctx.fill();

      // Draw the pupil of the eye
      ctx.beginPath();
      ctx.arc(eyeX, eyeY, eyeRadius / 3, 0, Math.PI * 2);
      ctx.fillStyle = "black";
      ctx.fill();

      // Calculate light ray angles
      const dx = centerX - eyeX;
      const dy = centerY - eyeY;
      const distanceToGlobe = Math.sqrt(dx * dx + dy * dy);
      const theta = Math.asin(globeRadius / distanceToGlobe); // Angle to the edge of the globe
      const baseAngle = Math.atan2(dy, dx);
      // const lightStartAngle = baseAngle - theta;
      // const lightEndAngle = baseAngle + theta;

      // Adjust the ray sector based on the anxiety level
      const sectorExpansion = 0.05 * anxietyLevel; // 5% increase per level
      const lightStartAngle = baseAngle - theta - sectorExpansion;
      const lightEndAngle = baseAngle + theta + sectorExpansion;

      // // Adjust color based on anxiety level (yellow to red gradient)
      // const anxietyColor = `rgb(255, ${255 - anxietyLevel * 51}, 0)`; // Gradual shift to red

      // Adjust color based on anxiety level (green to red gradient)
      // const anxietyColor = `rgb(${anxietyLevel * 51}, ${255 - anxietyLevel * 51}, 0)`; // Gradual shift to red

      // Adjust color based on anxiety level (blue to red gradient)
      const anxietyColor = `rgb(${anxietyLevel * 51}, 0, ${255 - anxietyLevel * 51})`; // Gradual shift from blue to red

      // Draw the light ray (sector from the eye to the globe)
      ctx.beginPath();
      ctx.moveTo(eyeX, eyeY); // Start at the center of the eye
      ctx.arc(eyeX, eyeY, globeRadius + 100, lightStartAngle, lightEndAngle); // Draw sector
      ctx.closePath();
      ctx.fillStyle = anxietyColor;; // Semi-transparent yellow
      ctx.fill();

      // Draw the globe as black
      ctx.beginPath();
      ctx.arc(centerX, centerY, globeRadius, 0, Math.PI * 2);
      ctx.fillStyle = "black"; // Black circle for the globe
      ctx.fill();

      // Create a radial gradient for the transition between light and dark
const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, globeRadius);

// Define color stops for the gradient
gradient.addColorStop(0, "rgba(255, 255, 255, 1)"); // Bright white center (light side)
gradient.addColorStop(0.7, "rgba(255, 255, 255, 0)"); // Fade to transparent
gradient.addColorStop(1, "rgba(0, 0, 0, 1)"); // Dark side (black)

// // Apply the gradient as a fill
// ctx.beginPath();
// ctx.arc(centerX, centerY, globeRadius, 0, Math.PI * 2);
// ctx.closePath();
// ctx.fillStyle = gradient;
// ctx.fill();


      // Draw the white sector from "8 to 1"
      const startWhiteAngle = (5.2 * Math.PI) / 6; // 8 o'clock
      const endWhiteAngle = -Math.PI / 2.735; // 1 o'clock
      const curveRadius = 20; // Adjust the radius of the curve for a smooth transition
      ctx.beginPath();
      ctx.moveTo(centerX, centerY); // Center of the globe
      ctx.arc(centerX, centerY, globeRadius, startWhiteAngle, endWhiteAngle, false); // Draw white sector
      
      ctx.closePath();
      ctx.fillStyle = "white"; // White fill for the sector
      ctx.fill();

      // Draw and update question marks
      questionMarks.forEach((mark) => {
        // Calculate rotated position of the question mark
        const rotatedX =
          centerX +
          (mark.x - centerX) * Math.cos(rotationAngle) -
          (mark.y - centerY) * Math.sin(rotationAngle);
        const rotatedY =
          centerY +
          (mark.x - centerX) * Math.sin(rotationAngle) +
          (mark.y - centerY) * Math.cos(rotationAngle);

        // Check if the question mark is in the white sector
        const markAngle = Math.atan2(rotatedY - centerY, rotatedX - centerX);
        const isInWhiteSector =
          markAngle >= startWhiteAngle && markAngle <= endWhiteAngle;

        // Draw the question mark only if it is outside the white sector
        if (!isInWhiteSector) {
          ctx.font = `${mark.size}px Arial`;
          ctx.fillStyle = "white"; // White question marks
          ctx.fillText("?", rotatedX, rotatedY);
        }
      });

      // Update the rotation angle
      rotationAngle = (rotationAngle + 0.01) % (2 * Math.PI); // Rotate the globe

      requestAnimationFrame(draw); // Animate
    };

    draw(); // Start the animation
  }, [anxietyLevel]);

// Global variables to keep track
// let currentN = 3;
// let isPaused = false;


  // Function to generate and play the speech
  const handlePlayPause = async (n) => {
    if (isPlaying) {
      // Pause the audio
      if (audio) {
        audio.pause();
        setIsPlaying(false);
        setIsPaused(true);
        console.log('pausing')

      }
    } else {
      // Resume playing
      console.log('Resume playing')

      if (audio && isPaused) {
        audio.play();
        setIsPlaying(true);
        setIsPaused(false);
      } else {


        if (currentN > 9) {
          console.log('restarting from 9')
          // Reset after reaching N=9 or if playback is stopped
          setIsPlaying(false);
          setIsPaused(false);
          setCurrentN(3);
          // setNLevel(3);
          return;
        }

      // Start from currentN
      setIsPlaying(true);
      // try {
      //   while (currentN <= 9) {
      //     const sentence = `I am the light that creates form out of uncertainties; therefore, I respect uncertainties ${currentN} times more than I did yesterday, and it respects me ${currentN} times more than it did, in return.`;

      //     // Call your TTS API here
      //     const response = await openai.audio.speech.create(
      //       {
      //         model: "tts-1-hd",
      //         input: sentence,
      //         voice: 'nova',
      //       },
      //       {
      //         headers: {
      //           'Content-Type': 'application/json',
      //           // 'Authorization': 'Bearer YOUR_API_KEY',
      //         },
      //         responseType: 'blob', // Specify response type as blob
      //       }
      //     );

      //     const audioData = await response.blob();
      //     const audioUrl = URL.createObjectURL(audioData);
      //     const newAudio = new Audio(audioUrl);
      //     setAudio(newAudio);

      //     // Play the audio and wait until it finishes
      //     await new Promise((resolve, reject) => {
      //       newAudio.play();
      //       setIsPlaying(true);

      //       newAudio.onended = () => {
      //         setIsPlaying(false);
      //         resolve();
      //         setCurrentN(currentN + 1);
      //         playAudioForN(currentN + 1);
      //       };
      //       newAudio.onerror = (e) => {
      //         console.error("Error playing audio:", e);
      //         reject(e);
      //       };

      //       // Handle pause
      //       newAudio.onpause = () => {
      //         setIsPlaying(false);
      //         setIsPaused(true)
      //       };
      //     });

      //     if (isPaused) {
      //       // Exit the loop if paused
      //       break;
      //     }

      //     // Increment N
      //     currentN++;
      //     setNLevel(currentN);
      //   }

      //   // Reset if finished
      //   if (currentN > 9) {
      //     currentN = 3;
      //     setNLevel(currentN);
      //   }
      // } catch (error) {
      //   console.error("Error generating speech:", error);
      //   setIsPlaying(false);
      //   setIsPaused(false);
      // }
      // playAudioForN(currentN);
      console.log('playing')
      try {
        while (currentN <= 9) {
          const sentence = `I am the light that creates form out of uncertainties; therefore, I respect uncertainties ${currentN} times more than I did yesterday, and it respects me ${currentN} times more than it did, in return.`;

          // Call your TTS API here
          const response = await openai.audio.speech.create(
            {
              model: "tts-1-hd",
              input: sentence,
              voice: 'nova',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer YOUR_API_KEY',
              },
              responseType: 'blob', // Specify response type as blob
            }
          );

          const audioData = await response.blob();
          const audioUrl = URL.createObjectURL(audioData);
          const newAudio = new Audio(audioUrl);
          setAudio(newAudio);

          // Play the audio and wait until it finishes
          await new Promise((resolve, reject) => {
            newAudio.play();
            setIsPlaying(true);

            newAudio.onended = () => {
              setIsPlaying(false);
              resolve();
              var fastN = currentN
              setCurrentN(fastN++);
              handlePlayPause(4)
              // setCurrentN(n + 1);
              // playAudioForN(n + 1);
            };
            newAudio.onerror = (e) => {
              console.error("Error playing audio:", e);
              reject(e);
            };

            // Handle pause
            newAudio.onpause = () => {
              setIsPlaying(false);
              setIsPaused(true)
            };
          });

          if (isPaused) {
            // Exit the loop if paused
            break;
          }

          // Increment N
          var fastN = currentN
          setCurrentN(fastN++);
          // currentN++;
          // setNLevel(currentN);
        }

        // Reset if finished
        if (currentN > 9) {
          currentN = 3;
          // setNLevel(currentN);
        }
      } catch (error) {
        console.error("Error generating speech:", error);
      }
    }
      // Generate the speech and play
      // const sentence = `I am the light that creates form out of uncertainties; therefore, I respect uncertainties ${nLevel} times more than I did yesterday, and it respects me ${nLevel} times more than it did, in return.`;

      // try {
      //   // Call your TTS API here
      //   // For demonstration, we'll use the Web Speech API
      //   // const utterance = new SpeechSynthesisUtterance(sentence);
      //   // window.speechSynthesis.speak(utterance);
      //   // setIsPlaying(true);

      //   // // Handle pause and resume
      //   // utterance.onpause = () => setIsPlaying(false);
      //   // utterance.onresume = () => setIsPlaying(true);
      //   // utterance.onend = () => setIsPlaying(false);

      //   // If using actual audio object
      //   const response = await openai.audio.speech.create(

      //   {
      //     model: "tts-1-hd",
      //     input: JSON.stringify({ text: sentence }),
      //     voice: 'nova'
      //   },
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: 'Bearer sk-proj-qtV3m5XrhzKtwoTyBOKUT3BlbkFJWg5KX1pdLEzC4EBzn5HC',
      //     },
      //     responseType: 'blob' // Specify response type as blob
      //   }
      
      // );
      //   // const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      //   // const audioBuffer = await audioContext.decodeAudioData(response.data);
      //   const audioData = await response.blob();
      //   const audioUrl = URL.createObjectURL(audioData);
      //   const newAudio = new Audio(audioUrl);
      //   newAudio.play();
      //   setAudio(newAudio);
      //   setIsPlaying(true);

      //   // Handle pause and resume
      //   newAudio.onpause = () => setIsPlaying(false);
      //   newAudio.onplay = () => setIsPlaying(true);
      //   newAudio.onended = () => setIsPlaying(false);
      // } catch (error) {
      //   console.error("Error generating speech:", error);
      // }
    }
  };

  const playAudioForN = async (n) => {
    if (n > 9 || !isPlaying) {
      // Reset after reaching N=9 or if playback is stopped
      setIsPlaying(false);
      setIsPaused(false);
      setCurrentN(3);
      // setNLevel(3);
      return;
    }
  
    // const sentence = `I am the light that creates form out of uncertainties; therefore, I respect uncertainties ${n} times more than I did yesterday, and it respects me ${n} times more than it did, in return.`;
  
    
     try {
        while (currentN <= 9) {
          const sentence = `I am the light that creates form out of uncertainties; therefore, I respect uncertainties ${currentN} times more than I did yesterday, and it respects me ${currentN} times more than it did, in return.`;

          // Call your TTS API here
          const response = await openai.audio.speech.create(
            {
              model: "tts-1-hd",
              input: sentence,
              voice: 'nova',
            },
            {
              headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer YOUR_API_KEY',
              },
              responseType: 'blob', // Specify response type as blob
            }
          );

          const audioData = await response.blob();
          const audioUrl = URL.createObjectURL(audioData);
          const newAudio = new Audio(audioUrl);
          setAudio(newAudio);

          // Play the audio and wait until it finishes
          await new Promise((resolve, reject) => {
            newAudio.play();
            setIsPlaying(true);

            newAudio.onended = () => {
              setIsPlaying(false);
              resolve();
              setCurrentN(n + 1);
              playAudioForN(n + 1);
            };
            newAudio.onerror = (e) => {
              console.error("Error playing audio:", e);
              reject(e);
            };

            // Handle pause
            newAudio.onpause = () => {
              setIsPlaying(false);
              setIsPaused(true)
            };
          });

          if (isPaused) {
            // Exit the loop if paused
            break;
          }

          // Increment N
          var fastN = currentN
          setCurrentN(fastN++)
          // currentN++;
          // setNLevel(currentN);
        }

        // Reset if finished
        if (currentN > 9) {
          currentN = 3;
          // setNLevel(currentN);
        }
      } catch (error) {
        console.error("Error generating speech:", error);
        setIsPlaying(false);
        setIsPaused(false);
      }
    
    // try {
    //   // Call your TTS API here
    //   const response = await fetch('/api/tts', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ sentence }),
    //   });
  
    //   const audioData = await response.arrayBuffer();
    //   const blob = new Blob([audioData], { type: 'audio/mpeg' });
    //   const audioUrl = URL.createObjectURL(blob);
    //   const newAudio = new Audio(audioUrl);
    //   setAudio(newAudio);
  
    //   newAudio.play();
    //   setIsPlaying(true);
    //   setNLevel(n);
  
    //   newAudio.onended = () => {
    //     // Proceed to the next N
    //     setCurrentN(n + 1);
    //     playAudioForN(n + 1);
    //   };
  
    //   newAudio.onpause = () => {
    //     // Audio paused
    //     setIsPlaying(false);
    //     setIsPaused(true);
    //   };
  
    //   newAudio.onerror = (e) => {
    //     console.error('Error playing audio:', e);
    //     setIsPlaying(false);
    //     setIsPaused(false);
    //   };
    // } catch (error) {
    //   console.error('Error generating speech:', error);
    //   setIsPlaying(false);
    //   setIsPaused(false);
    // }
  };
  

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        padding: "20px",
        boxSizing: "border-box",
        // height: "100vh",
        // backgroundColor: "#f0f8ff",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "500px",
          position: "relative",
        }}
      >
        <canvas
          ref={canvasRef}
          width={500}
          height={500}
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid black",
          }}
        />
      </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
  {/* <label htmlFor="anxiety-slider" style={{ marginBottom: "10px", display: "block" }}>
    Anxiety Level: {anxietyLevel.toFixed(1)}
  </label> */}
  <input
    id="anxiety-slider"
    type="range"
    min="0"
    max="5"
    value={anxietyLevel}
    onChange={(e) => {
      const newValue = Number(e.target.value);
      setAnxietyLevel(newValue);

      // Automatically slide back to 0
      const slideBackInterval = setInterval(() => {
        setAnxietyLevel((prev) => {
          if (prev > 0) {
            return prev - 0.1; // Decrease the value
          } else {
            clearInterval(slideBackInterval); // Stop the interval when it reaches 0
            return 0;
          }
        });
      }, 100); // Adjust the speed of the slide back
    }}
    style={{ width: "300px", margin: "10px" }}
  />
</div>


      {/* New Range Slider for N Level */}
      {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
        <label
          htmlFor="n-level-slider"
          style={{ marginBottom: "10px", display: "block" }}
        >
          N Level: {currentN}
        </label>
        <input
          id="n-level-slider"
          type="range"
          min="3"
          max="9"
          value={currentN}
          onChange={(e) => setCurrentN(Number(e.target.value))}
          style={{ width: "300px", margin: "10px" }}
        />
      </div> */}

      {/* Play/Pause Button */}
      {/* <button
        onClick={(e) => handlePlayPause(currentN)}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          fontSize: "16px",
        }}
      >
        {isPlaying ? "Pause" : "Play"}
      </button> */}
    </div>
  );
};

export default DayNight;