import React, { useState } from 'react';

const AntiProcastinator = () => {
  const [value, setValue] = useState(0); // Start balanced

  // Angle calculation remains the same
  const angle = 20 - (value * 4);

  const width = 350;
  const height = 350;
  const centerX = width / 2; 
  const centerY = height / 2;
  const beamLength = 180; 
  const panRadius = 25;

  const rad = (angle * Math.PI) / 180;

  const leftX = centerX - beamLength/2;
  const leftY = centerY;
  const rightX = centerX + beamLength/2;
  const rightY = centerY;

  const newLeftX = centerX + Math.cos(rad)*(-beamLength/2);
  const newLeftY = centerY + Math.sin(rad)*(-beamLength/2);

  const newRightX = centerX + Math.cos(rad)*(beamLength/2);
  const newRightY = centerY + Math.sin(rad)*(beamLength/2);

  const fraction = value/10;

  const grey = "#4CAF50";
  const red = "#f02626";

  // Present Me logic:
  // fraction=0: full grey
  // fraction=0.5: bottom 50% red, top 50% grey
  // fraction=1: full red
  // For a top-to-bottom gradient: 
  // offset=0 is top, offset=1 is bottom
  // The bottom fraction is red, so red starts at offset=(1 - fraction)
  // 0 to (1 - fraction): grey
  // (1 - fraction) to 1: red
  let presentFillId = "presentGradient";
  let presentStops = null;
  let presentSolid = null;
  if (fraction <= 0.0001) {
    // just grey
    presentFillId = null;
    presentSolid = grey;
  } else if (fraction >= 0.9999) {
    // just red
    presentFillId = null;
    presentSolid = red;
  } else {
    // partial gradient
    const cutoff = 1 - fraction; // where red starts
    presentStops = [
      {offset:0, color:grey},
      {offset:cutoff, color:grey},
      {offset:cutoff, color:red},
      {offset:1, color:red}
    ];
  }

  // Future Me logic unchanged from previous code
  // Let's just keep future me as full circle red at 0, half at 5, grey at 10
  // This logic can remain same as previous instructions:
  let futureArcDeg;
  if (value <= 5) {
    futureArcDeg = 360 - ((value/5)*180);
  } else {
    futureArcDeg = 180 - (((value-5)/5)*180);
  }

  // For simplicity, leave future me unchanged as requested:
  let futureSolid = null;
  let futureFillId = "futureGradient";
  let futureStops = null;
  if (value <= 0.0001) {
    futureSolid = red;
    futureFillId = null;
  } else if (value >= 9.9999) {
    futureSolid = grey;
    futureFillId = null;
  } else {
    // partial logic same as original for future me:
    let fractionF = value/10;
    // future me: top portion grey, bottom red at fraction=0.5 is half half
    // 0 => full red, 5 => half grey half red, 10 => full grey
    // Means top fractionF grey, rest red
    futureStops = [
      {offset:0, color:grey},
      {offset:fractionF, color:grey},
      {offset:fractionF, color:red},
      {offset:1, color:red}
    ];
  }

  const circleElement = (cx, cy, r, fillId, solidColor) => {
    if (solidColor) {
      return <circle cx={cx} cy={cy} r={r} fill={solidColor} stroke="white"/>;
    } else if (fillId) {
      return <circle cx={cx} cy={cy} r={r} fill={`url(#${fillId})`} stroke="white"/>;
    } else {
      return null;
    }
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f0f0f5',
      minHeight: '100vh',
      padding: '10px',
      boxSizing: 'border-box',
      overflowX: 'hidden'
    }}>
      <div style={{
        background: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        padding: '20px',
        maxWidth: '500px',
        width: '100%',
        textAlign: 'center',
        margin: '0 auto'
      }}>
        <div style={{ overflow: 'hidden', marginBottom: '20px', width: '100%' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <svg 
              width={350} 
              height={350} 
              style={{ border: '1px solid #ccc', borderRadius: '8px', background: '#fafafa', maxWidth: '100%' }} 
              viewBox="0 0 350 350"
            >
              <defs>
                {presentStops && (
                  <linearGradient id="presentGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    {presentStops.map((stop, i) => (
                      <stop key={i} offset={`${stop.offset*100}%`} stopColor={stop.color}/>
                    ))}
                  </linearGradient>
                )}
                {futureStops && (
                  <linearGradient id="futureGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    {futureStops.map((stop, i) => (
                      <stop key={i} offset={`${stop.offset*100}%`} stopColor={stop.color}/>
                    ))}
                  </linearGradient>
                )}
              </defs>

                 {/* Horizontal slab under stand */}
                 <rect 
                x={centerX - 200/2} 
                y={centerY + 180 + 10} 
                width={200} 
                height={10} 
                fill="black" 
              />


              {/* Base or stand */}
              <line 
                x1={175} 
                y1={175+200} 
                x2={175} 
                y2={175-100} 
                stroke="black" 
                strokeWidth={7} 
              />

              {/* Pivot */}
              <circle cx={175} cy={175} r={5} fill="black" />

              {/* Beam line */}
              <line
                x1={newLeftX}
                y1={newLeftY}
                x2={newRightX}
                y2={newRightY}
                stroke="black"
                strokeWidth={4}
              />

              {/* Left pan line */}
              <line
                x1={newLeftX}
                y1={newLeftY}
                x2={newLeftX}
                y2={newLeftY + 60}
                stroke="black"
                strokeWidth={0.5}
              />

              {/* Present Me circle with gradient */}
              {circleElement(newLeftX, newLeftY+85, panRadius, presentSolid?null:presentFillId, presentSolid)}

              <text 
                x={newLeftX} 
                y={newLeftY + 85 + panRadius + 15} 
                textAnchor="middle" 
                style={{ fontSize: '14px', fill: '#333' }}
              >
                Present Self
              </text>

              {/* Right pan line */}
              <line
                x1={newRightX}
                y1={newRightY}
                x2={newRightX}
                y2={newRightY + 60}
                stroke="black"
                strokeWidth={0.5}
              />

              {/* Future Me circle with gradient */}
              {circleElement(newRightX, newRightY+85, panRadius, futureSolid?null:futureFillId, futureSolid)}

              <text 
                x={newRightX} 
                y={newRightY + 85 + panRadius + 15} 
                textAnchor="middle" 
                style={{ fontSize: '14px', fill: '#333' }}
              >
                Future Self
              </text>
            </svg>
          </div>
        </div>

        {/* <div style={{ marginBottom: '5px', color: '#555', fontSize: '16px' }}>
            I've completed {value * 10}% of the Job
        </div> */}
        <input
          type="range"
          min="0"
          max="10"
          value={value}
          onChange={(e) => setValue(Number(e.target.value))}
          style={{
        width: '80%',
        marginTop: '5px',
        appearance: 'none',
        height: '35px',
        borderRadius: '5px',
        background: '#CED4DA',
        outline: 'none',
        backgroundImage: `linear-gradient(to right, ${
          value >= 5 ? '#4CAF50' : '#4CAF50'
        } 0%, ${
          value >= 5 ? '#4CAF50' : '#4CAF50'
        } ${(value / 10) * 100}%, #CED4DA ${(value / 10) * 100}%, #CED4DA 100%)`,
      }}
        />
      </div>
    </div>
  );
};

export default AntiProcastinator;
