import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const FutureTree = () => {
  const questions = [
    "What impact will the result of this event have in a day?",
    "What impact will the result of this event have in a month?",
    "What impact will the result of this event have in 6 months?",
    "What impact will the result of this event have in a year?",
    "What impact will the result of this event have in 5 years?",
    "What impact will the result of this event have in 10 years?",
    "What impact will the result of this event have in 50 years?",
    "What impact will the result of this event have on your deathbed?",
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleAnswer = (answer) => {
    setAnswers([...answers, answer]);
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true); // Show results after the last question
    }
  };

  const getLineCoordinates = () => {
    const coords = [{ x: 50, y: 200 }]; // Starting point
    let x = 50;
    let y = 200;

    answers.forEach((answer) => {
      x += 50; // Adjust horizontal spacing for smaller screens
      if (answer === "good") y -= 30; // Line goes up
      else if (answer === "bad") y += 30; // Line goes down
      coords.push({ x, y });
    });

    return coords;
  };

  const renderVisualization = () => {
    // const coordinates = getLineCoordinates();
  
    const fixedSegmentLength = 100; // Fixed length for all lines
    let previousPoint = { x: 50, y: 200 }; // Start point (initial)
  
    const calculateNewPoint = (start, angle, length) => {
      return {
        x: start.x + length * Math.cos(angle),
        y: start.y + length * Math.sin(angle),
      };
    };
  
    return (
      <div
        className="p-3 rounded shadow"
        style={{
          background: "linear-gradient(120deg, #ffffff, #f8f9fa)",
        }}
      >
        <h4 className="text-center mb-3">Your Event Impact Visualization</h4>
        <div
          style={{
            overflowX: "auto", // Allow horizontal scrolling if necessary
            overflowY: "hidden",
            whiteSpace: "nowrap",
            margin: "0 auto",
          }}
        >
          <svg
            width={Math.max(300, 60 * answers.length)} // Dynamic width for answers
            height="300"
            viewBox="0 0 800 300" // Ensure it scales properly
            style={{
              border: "1px solid #dee2e6",
              borderRadius: "10px",
              display: "block",
              margin: "0 auto",
              maxWidth: "100%", // Fit within mobile screen width
            }}
          >
            {answers.map((answer, index) => {
              const angle =
                answer === "good"
                  ? -Math.PI / 4 // Line goes up
                  : answer === "bad"
                  ? Math.PI / 4 // Line goes down
                  : 0; // Neutral (horizontal)
  
              // Calculate the new point based on a fixed length and the angle
              const newPoint = calculateNewPoint(previousPoint, angle, fixedSegmentLength);
  
              const line = (
                <line
                  key={`line-${index}`}
                  x1={previousPoint.x}
                  y1={previousPoint.y}
                  x2={newPoint.x}
                  y2={newPoint.y}
                  stroke="#007bff"
                  strokeWidth="3"
                />
              );
  
              const circle = (
                <circle
                  key={`circle-${index}`}
                  cx={newPoint.x}
                  cy={newPoint.y}
                  r="5"
                  fill="#343a40"
                  stroke="#007bff"
                  strokeWidth="2"
                />
              );
  
              // Update the previous point for the next iteration
              previousPoint = newPoint;
  
              return (
                <React.Fragment key={index}>
                  {line}
                  {circle}
                </React.Fragment>
              );
            })}
          </svg>
        </div>
      </div>
    );
  };
  

  

  
  


  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          {!showResults ? (
            currentQuestion < questions.length ? (
              <div className="card shadow-lg p-3">
                <h5 className="text-center mb-3">{questions[currentQuestion]}</h5>
                <div className="d-flex justify-content-center gap-2 flex-wrap">
                  <button
                    className="btn btn-success px-3 py-2"
                    onClick={() => handleAnswer("good")}
                  >
                    Good
                  </button>
                  <button
                    className="btn btn-secondary px-3 py-2"
                    onClick={() => handleAnswer("neutral")}
                  >
                    I don't know
                  </button>
                  <button
                    className="btn btn-danger px-3 py-2"
                    onClick={() => handleAnswer("bad")}
                  >
                    Bad
                  </button>
                </div>
              </div>
            ) : (
              <div className="card shadow-lg p-4 text-center">
                <h5 className="mb-3">Thank you for answering all the questions!</h5>
                <p>Click the button below to see your result visualization.</p>
                <button
                  className="btn btn-primary px-4 py-2"
                  onClick={() => setShowResults(true)}
                >
                  Show Results
                </button>
              </div>
            )
          ) : (
            renderVisualization()
          )}
        </div>
      </div>
    </div>
  );
};

export default FutureTree;
