import React, { useState, useEffect } from "react";
import Earth from "./Earth";
import UserPosture from "./UserPosture";
import "./styles.css";

const ExperiencesDie = () => {
  const [screen, setScreen] = useState(1); // 1: posture question, 2: force question, 3: visualization
  const [posture, setPosture] = useState("sitting"); // Default posture
  const energies = [50, 60, 70, 80, 90, 100]; 
  const [index, setIndex] = useState(0);
  const [energy, setEnergy] = useState(energies[0]); // Default energy level at 50%


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setIndex((prevIndex) => (prevIndex + 1) % energies.length);
  //   }, 500); // Every 0.5 seconds
  //   return () => clearInterval(interval);
  // }, [energies.length]);

  // useEffect(() => {
  //   setEnergy(energies[index]);
  // }, [index, energies]);
   // Only run cycling energy when on the final screen
    useEffect(() => {
      let interval;
      if (screen === 3) {
        interval = setInterval(() => {
          setIndex((prevIndex) => (prevIndex + 1) % energies.length);
        }, 500); // Every 0.5 seconds
      }
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [screen, energies.length]);

    useEffect(() => {
      if (screen === 3) {
        setEnergy(energies[index]);
      }
    }, [index, energies, screen]);
  
    const handlePostureNext = () => {
      if (posture) {
        setScreen(2);
      } else {
        alert("Please select your current position before proceeding.");
      }
    };
  
    const getForceQuestion = () => {
      // Return the question based on the chosen posture
      switch (posture) {
        case "standing":
          return "Do you feel a tight force pulling you towards the ground on your legs?";
        case "sitting":
          return "Do you feel a tight force pulling you towards the ground on your back and buttocks?";
        case "laying":
          return "Do you feel a tight force pulling you towards the ground on the lower part of your whole body?";
        default:
          return "";
      }
    };
  
    const handleForceAnswer = (answer) => {
      if (answer === "yes") {
        setScreen(3);
      } else {
        setScreen("finishNo"); // A separate end state if user says no
      }
    };
  
    const modernContainerStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      padding: 20,
      fontFamily: "'Roboto', sans-serif",
      color: "#333"
    };
  
    const cardStyle = {
      background: "#fff",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
      padding: "30px",
      maxWidth: "400px",
      width: "100%",
      textAlign: "center"
    };
  
    const headingStyle = {
      fontSize: "1.2rem",
      marginBottom: "20px"
    };
  
    const buttonStyle = {
      background: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "4px",
      padding: "10px 20px",
      fontSize: "16px",
      cursor: "pointer",
      margin: "10px 5px",
    };
  
    const finishButtonStyle = {
      ...buttonStyle,
      background: "#28a745"
    };
  
    // Render based on screen state
    if (screen === 1) {
      // First Question Screen
      return (
        <div style={modernContainerStyle}>
          <div style={cardStyle}>
            <h2 style={headingStyle}>What position are you in right now?</h2>
            <div style={{ marginBottom: "20px", textAlign:'left' }}>
              <label style={{ display: "block", margin: "10px 0" }}>
                <input
                  type="radio"
                  name="posture"
                  value="standing"
                  onChange={(e) => setPosture(e.target.value)}
                  checked={posture === "standing"}
                /> Standing
              </label>
              <label style={{ display: "block", margin: "10px 0" }}>
                <input
                  type="radio"
                  name="posture"
                  value="sitting"
                  onChange={(e) => setPosture(e.target.value)}
                  checked={posture === "sitting"}
                /> Sitting
              </label>
              <label style={{ display: "block", margin: "10px 0" }}>
                <input
                  type="radio"
                  name="posture"
                  value="laying"
                  onChange={(e) => setPosture(e.target.value)}
                  checked={posture === "laying"}
                /> Laying
              </label>
            </div>
            <button style={buttonStyle} onClick={handlePostureNext}>
              Next
            </button>
          </div>
        </div>
      );
    } else if (screen === 2) {
      // Second Question Screen
      return (
        <div style={modernContainerStyle}>
          <div style={cardStyle}>
            <h2 style={headingStyle}>{getForceQuestion()}</h2>
            <div>
              <button style={buttonStyle} onClick={() => handleForceAnswer("yes")}>
                Yes
              </button>
              <button style={buttonStyle} onClick={() => handleForceAnswer("no")}>
                No
              </button>
            </div>
          </div>
        </div>
      );
    } else if (screen === 3) {
      // Visualization Screen (Earth and energy cycling)
      return (
        <div className="app"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            textAlign: "center",
            marginTop: 40,
          }}
        >
          {/* <h7>That feeling in your body, is a constant hug from Mother Earth.</h7> */}
          <div
            className="visualization"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vw",
              width: "80vw",
              maxHeight: "500px",
              maxWidth: "500px",
              position: "relative",
              marginTop: 30,
            }}
          >
            <Earth />
            <UserPosture posture={'sitting'} energy={energy} />
          </div>
          <div style={{marginTop:25}}>
            <button style={finishButtonStyle} onClick={() => window.location.reload()}>
              Finish
            </button>
          </div>
          
        </div>
      );
    } else if (screen === "finishNo") {
      // If user said no, they can't progress, show finish
      return (
        <div style={modernContainerStyle}>
          <div style={cardStyle}>
            <h2 style={headingStyle}>No worries!</h2>
            <p>You can end the experience now.</p>
            <button style={finishButtonStyle} onClick={() => window.location.reload()}>
              Finish
            </button>
          </div>
        </div>
      );
    }
  
    return null;
  };
  
  export default ExperiencesDie;

  // return (
  //   <div
  //     className="app"
  //     style={{
  //       display: "flex",
  //       flexDirection: "column",
  //       // justifyContent: "center",
  //       alignItems: "center",
  //       height: "100vh", // Full viewport height
  //       textAlign: "center",
  //       marginTop:40,
  //     }}
  //   >
  //     <h7>Gravity is the way Mother Earth hugs her children.</h7>

  //     <div className="controls" style={{ marginBottom: "20px" }}>
  //       {/* <label htmlFor="posture">Select Posture: </label>
  //       <select
  //         id="posture"
  //         value={posture}
  //         onChange={(e) => setPosture(e.target.value)}
  //         style={{
  //           padding: "5px",
  //           fontSize: "16px",
  //           marginRight: "10px",
  //         }}
  //       >
  //         <option value="standing">Standing</option>
  //         <option value="sitting">Sitting</option>
  //         <option value="laying">Laying</option>
  //       </select> */}

  //       {/* <label htmlFor="energy">Set Energy: </label> */}
  //       {/* <input
  //         id="energy"
  //         type="number"
  //         value={energy}
  //         onChange={(e) => setEnergy(Math.max(0, Math.min(100, e.target.value)))} // Clamp value between 0 and 100
  //         style={{
  //           padding: "5px",
  //           fontSize: "16px",
  //           width: "60px",
  //           marginLeft: "10px",
  //         }}
  //       />% */}
  //     </div>

  //     <div
  //       className="visualization"
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "80vw", // Dynamic scaling
  //         width: "80vw",
  //         maxHeight: "500px", // Cap the size for larger screens
  //         maxWidth: "500px",
  //         position: "relative",
  //         marginTop:30

  //       }}
  //     >
  //       <Earth />
  //       <UserPosture posture={posture} energy={energy} />
  //     </div>
  //   </div>
  // );
// };

// export default ExperiencesDie;
